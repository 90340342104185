@import "~app/mixins";

:global {
  @import "~react-image-gallery/styles/scss/image-gallery.scss";
}

.galleryOuter {
  width: 95% !important;
  height: 95% !important;
  //max-height: 1300px !important;
  //max-width: 1600px;

  .gallery {
    display: flex;
    justify-content: center;
    color: $color-grey;

    .left {
      width: 350px;
      margin-right: 25px;
      height: calc(100% - 20px);
      overflow-y: auto;

      .imagePanel {
        padding: 2px 6px;
        margin: 3px;

        .date {
          margin: 8px 0 2px 6px;
        }

        .images {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .imageOuter {
            border: 2px solid transparent;
            padding: 4px;
            margin-bottom: 4px;

            &.selected {
              @include primary-border-color();
            }

            .image {
              border: 1px solid #666;
              width: 154px;
              height: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              cursor: pointer;

              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    .right {
      width: 1000px;
      flex: 1;
      overflow: hidden;
      height: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .viewer {
        margin-top: 3px;
        height: calc(100% - 255px);

        > div {
          width: 100%;
          height: 100%;

          :global {
            $radius: 5px;
            $colorArrow: #d8d8d8;
            $offset: 2px;

            .image-gallery {
              height: 100%;
            }

            .image-gallery-content {
              height: 100%;
              flex-direction: column;
              display: flex;
              justify-content: flex-end;
            }

            .image-gallery-slide-wrapper {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }

            .image-gallery-slide img {
              max-height: calc(100vh - 370px);
              max-width: 100%;
              width: inherit;
              display: block;
              margin: 0 auto;
              border-radius: $radius;
              object-fit: cover;
            }

            .image-gallery-left-nav,
            .image-gallery-right-nav {
              top: inherit;
              padding: 0;
              bottom: -132px;
              transform: none;
              width: 0;
              height: 0;
              border-top: 62px solid transparent;
              border-bottom: 62px solid transparent;
              transition: border $transition-delay;

              &:before {
                display: none;
              }
            }

            .image-gallery-left-nav {
              border-right: 26px solid $colorArrow;
              left: -35px;

              &:hover {
                border-right-color: $color-grey-light;
              }
            }

            .image-gallery-right-nav {
              border-left: 26px solid $colorArrow;
              right: -35px;

              &:hover {
                border-left-color: $color-grey-light;
              }
            }

            .image-gallery-slides {
              border-radius: $radius;
            }
          }
        }
      }

      .bottom {
        .date {
          text-align: right;
          margin: 10px;
          height: 20px;
        }

        .details {
          padding: 12px 0;
          margin: 3px;

          .items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            height: 140px;

            > div {
              width: 33%;
              background-color: transparent;

              &:nth-child(6n + 4),
              &:nth-child(6n + 5),
              &:nth-child(6n + 6) {
                background-color: #f5f5f5;
              }
            }
          }
        }
      }

      .buttons {
        margin-top: 15px;
        text-align: right;

        button {
          width: 130px;
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }
}

.modalWrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: -13px;
  padding-bottom: 15px;
}

.headerTitle {
  color: $color-blue-dark;
  font-size: 16px;
  line-height: 18px;
  padding-top: 10px;
  padding-right: 20px;

  @include text-overflow;
}

.buttons {
  text-align: center;

  button {
    margin: 0 8px;
    width: 150px;
  }
}

.container {
  height: calc(100% - 65px);
  overflow: hidden;
}

.galleryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.gallery {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
